<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { computed } from '#imports'
  import {
    GameEquipmentCategoryId,
    GameTrouvailleId,
    GameTrouvailleListContent,
  } from '~/model/GameTrouvaille/GameTrouvaille'
  import useTranslation from '~/composables/Translation/Translation'
  import useGameTrouvaille from '~/composables/GameTrouvaille/GameTrouvaille'
  import { CinemaDraggableFacet } from '~/components/CinemaDraggable/CinemaDraggableFacet'
  import useTrouvailleEquipment from '~/composables/TrouvailleEquipment/TrouvailleEquipment'
  import { useGameCinemaStore } from '~/store/GameCinema'
  import useBem from '~/composables/Bem/Bem'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'

  interface Props extends UseBemProps {
    equipmentCategoryId: GameEquipmentCategoryId
    equipmentList: Array<GameTrouvailleListContent>
  }

  const gameCinemaStore = useGameCinemaStore()

  const props = defineProps<Props>()
  const { draggingEquipmentCategoryId } = storeToRefs(gameCinemaStore)
  const { bemAdd, bemFacets } = useBem('c-list-equipment', props, {})
  const { t, tPl } = useTranslation()
  const { getTrouvailleIcon } = useGameTrouvaille()
  const { unequipTrouvaille } = useTrouvailleEquipment()

  const filteredEquipmentList = computed<Array<GameTrouvailleListContent>>(() => {
    return props.equipmentList.filter(({ equipmentCategoryId }) => {
      return equipmentCategoryId === props.equipmentCategoryId
    })
  })

  const subTitle = computed<string>(() => {
    return t('sidebar-content-cinema-section.available-items', {
      item: tPl(`cinema-equipment.${props.equipmentCategoryId}`, 2),
    })
  })

  const noItems = computed<string>(() => {
    return t('sidebar-content-cinema-section.no-items', {
      item: tPl(`cinema-equipment.${props.equipmentCategoryId}`, 2),
    })
  })

  const onUnset = (e: DragEvent) => {
    if (!e.dataTransfer) {
      throw new Error('No dataTransfer object found!')
    }

    unequipTrouvaille(e.dataTransfer.getData('id') as GameTrouvailleId)
  }

  const rootClasses = computed<Array<string>>(() => {
    const isDragging = draggingEquipmentCategoryId.value === props.equipmentCategoryId
    return [bemAdd(isDragging ? 'is-dragging' : '')]
  })
</script>

<template>
  <div :class="rootClasses" class="c-list-equipment">
    <h2 class="s-sidebar-content__entries-title">
      {{ subTitle }}
    </h2>
    <div
      class="s-sidebar-content__entries-content c-list-equipment__items"
      @drop="onUnset($event)"
      @dragover.prevent
      @dragenter.prevent
    >
      <Transition mode="out-in" name="trs-simple-fade">
        <template v-if="filteredEquipmentList.length > 0">
          <ul class="c-list-equipment__list u-reset">
            <li
              v-for="equipmentEntry in filteredEquipmentList"
              :key="equipmentEntry.id"
              class="c-list-equipment__entry"
            >
              <CinemaDraggable
                :draggable-id="equipmentEntry.id"
                :equipment-category-id="equipmentEntry.equipmentCategoryId"
                :facets="[CinemaDraggableFacet.InStagingZone]"
                class="c-list-equipment__draggable"
              >
                <template #content>
                  <div class="c-list-equipment__tooltip">
                    {{ equipmentEntry.name }}
                  </div>
                  <ResponsiveImg
                    :src="getTrouvailleIcon(equipmentEntry)"
                    :width="80"
                    alt=""
                    class="c-list-equipment__icon"
                  />
                </template>
              </CinemaDraggable>
            </li>
          </ul>
        </template>
        <template v-else>
          <span class="c-list-equipment__no-items">
            {{ noItems }}
          </span>
        </template>
      </Transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:color';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/util/transition' as trs;
  @use '/assets/scss/base/font/font' as font;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/color/color' as col;

  .c-list-equipment__items {
    padding: 8px;
    background-color: col.$monochrome-black;
  }

  .c-list-equipment__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
  }

  .c-list-equipment__icon {
    width: 100%;
    height: 100%;
  }

  .c-list-equipment__no-items {
    @include type.copy-x-small;
    pointer-events: none;
    display: block;
    padding: 8px 16px;
    text-align: center;
    color: col.$monochrome-tornado;
  }

  .c-list-equipment__draggable {
    @include utils.has-focus {
      .c-list-equipment__tooltip {
        @include utils.is-visible(true, trs.$default-speed, opacity, transform);
        opacity: 1;
        transform: translateY(-4px);
      }
    }
  }

  .c-list-equipment__tooltip {
    @include utils.is-visible(false, trs.$default-speed, opacity, transform);
    @include type.copy-x-small;
    @include font.base-bold;
    @include trs.common-props;
    position: absolute;
    bottom: 100%;
    left: -1px;
    display: block;
    background-color: col.$monochrome-black;
    border: 1px solid col.$monochrome-lead;
    color: col.$monochrome-white;
    padding: 3px 6px;
    border-radius: 2px;
    white-space: nowrap;

    opacity: 0;
    transform: translateY(0);
  }

  .c-list-equipment--is-dragging {
    .c-list-equipment__items {
      background-color: color.mix(col.$monochrome-black, col.$variant-cyan-dark, 60%);
    }
  }
</style>
